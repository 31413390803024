.dataimg-camera {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/camera.svg"); background-repeat: no-repeat;}

.dataimg-facebook {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/facebook.svg"); background-repeat: no-repeat;}

.dataimg-loader {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/loader.svg"); background-repeat: no-repeat;}

.dataimg-login {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/login.svg"); background-repeat: no-repeat;}

.dataimg-logo {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/logo.svg"); background-repeat: no-repeat;}

.dataimg-play {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/play.svg"); background-repeat: no-repeat;}

.dataimg-tag-blesk-premium {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/tag-blesk-premium.svg"); background-repeat: no-repeat;}

.dataimg-twitter {background-image: url("https://img2.cncenter.cz/images/blesk/dist/deploy-2.0/svg/twitter.svg"); background-repeat: no-repeat;}

.dataimg-inst {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/inst.svg"); background-repeat: no-repeat;}

.dataimg-krizek {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/krizek.svg"); background-repeat: no-repeat;}

.dataimg-social-line-facebook {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/social-line-facebook.svg"); background-repeat: no-repeat;}

.dataimg-social-line-mail {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/social-line-mail.svg"); background-repeat: no-repeat;}

.dataimg-social-line-messenger {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/social-line-messenger.svg"); background-repeat: no-repeat;}

.dataimg-social-line-twitter {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/social-line-twitter.svg"); background-repeat: no-repeat;}

.dataimg-social-line-whatsapp {background-image: url("https://img2.cncenter.cz/images/blesk/dist/svg/social-line-whatsapp.svg"); background-repeat: no-repeat;}